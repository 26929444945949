<template>
    <div style="padding-top: 8em;">
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <section class="intro clearfix header_service">
            <div class="container">
                <div class="row">
                    <div class="overlay-green"></div>
                            <div class="col-md-12 intro-info text-left" style="z-index: 1;">
                                <h1 class="text-uppercase bold text-white mb-4 text-shadow ls-1">Track your Company Incorporation Progress here!</h1>
                                <p class="h5 mb-5 text-white text-shadow">
                                    Enter your ID Order
                                </p>
                            <div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-5 mb-12" style="padding-bottom: 150px;">
            <v-container class="container">
                <v-row align="center" justify="center">
                    <div class="col-sm-9">
                        <v-text-field
                            class="border-12"
                            height="40"
                            solo
                            label="Please enter your ID Order "
                            append-icon="mdi-magnify"
                            hide-details=""
                            v-model="order_id"
                            @keyup.enter="submit()"
                        ></v-text-field>
                    </div>
                </v-row>
                <v-row align="center" justify="center" v-if="showEmpty">
                    <div class="col-sm-3">
                        <img src="@/assets/tracking.png" class="img-fluid">
                    </div>
                </v-row>
                <v-row v-else align="center" justify="center">
                    <v-col cols="8">
                        <v-card flat class="rounded-l green lighten-4">
                            <v-card-text>
                                <h5 style="font-weight:600" class="pa-0 ma-0 green--text text-center">
                                    {{order ? order.order_id : ''}} 
                                </h5>
                                <v-divider class="mb-0 pb-0"></v-divider>
                                <v-row align="center" justify="center">
                                    <v-col cols="4">
                                        <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#c8e6c9">
                                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title style="font-size: 14px;">{{order ? order.order_id : ''}}</v-list-item-title>
                                                    <v-list-item-subtitle class="font-12">Order ID</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title style="font-size: 14px;">{{order ? order.service.name : ''}}</v-list-item-title>
                                                    <v-list-item-subtitle class="font-12">Layanan</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title style="font-size: 14px;">{{order ? order.company_name : ''}}</v-list-item-title>
                                                    <v-list-item-subtitle class="font-12">Company</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#c8e6c9">
                                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title style="font-size: 14px;">{{order ? order.status : ''}}</v-list-item-title>
                                                    <v-list-item-subtitle class="font-12">Status</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title style="font-size: 14px;">{{order ? order.name : ''}}</v-list-item-title>
                                                    <v-list-item-subtitle class="font-12">Name</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title style="font-size: 14px;">{{order ? order.no_telp : ''}}</v-list-item-title>
                                                    <v-list-item-subtitle class="font-12">No Telp</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="8" v-if="order">
                        <v-timeline>
                            <v-timeline-item
                            v-for="(ord, i) in order.order_histories"
                            :key="i"
                            small
                            >
                                <template v-slot:opposite>
                                    <span
                                    :class="`headline font-weight-bold primary--text`"
                                    v-text="new Date(ord.trans_date).toISOString().substr(0, 10)"
                                    ></span>
                                </template>
                                <v-card class="elevation-2">
                                    <v-card-text>
                                        <h5 :class="`headline font-weight-light mb-4 green--text`" v-if="ord.status === 'onprogress'">
                                            On Progress
                                        </h5>
                                        <h5 :class="`headline font-weight-light mb-4 purple--text`" v-else-if="ord.status === 'done'">
                                            DONE
                                        </h5>
                                        <h5 :class="`headline font-weight-light mb-4 red--text`" v-else>
                                            Cancel
                                        </h5>
                                        <div>
                                            {{ ord.description }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <!-- <contact-us></contact-us> -->
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {order} from "@/backend-api/order"
import {service_api} from "@/backend-api/service_api"

import ContactUs from "@/components/layout_front/ContactUs.vue"

export default {
    components: {
        ContactUs
    },
    data: () => ({
        snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false
        },
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Konsultasi Hukum',
                disabled: true,
            }
        ],
        siteKey: '',
        headers:[
            { text: 'Kode', value: 'code' , align:'left', width:'200'},
            { text: 'Judul', value: 'title' , align:'left', width:'400'},
            { text: 'Katerangan', value: 'description' , align:'left'},
        ],
        itemLists: [],
        searchItem: '',
        headersColumn: {
            'Kode': 'code',
            'Judul': 'title',
            'Katerangan': 'description'
        },
        order:null,
        showEmpty: true,
        years: [
            {
            color: 'cyan',
            year: '1960',
            },
            {
            color: 'green',
            year: '1970',
            },
            {
            color: 'pink',
            year: '1980',
            },
            {
            color: 'amber',
            year: '1990',
            },
            {
            color: 'orange',
            year: '2000',
            },
        ],
        order_id: '',
    }),
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async submit() {
            if (this.order_id.trim() == '') {
                this.snackbar = {
                    color: "info",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Info",
                    text: 'Please fill your order id to search',
                    visible: true
                };

                return false
            }

            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'order_id' : this.order_id
            }
            const respData = await order.tracking('', reqBody, false, false, false, false)
            if (respData.status === 200) {
                console.log(respData.data.data);
                this.$store.dispatch('setOverlay', false)
                this.order = respData.data.data

                if (this.order) {
                this.showEmpty = false
                } else {
                this.showEmpty = true
                this.snackbar = {
                    color: "info",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Info",
                    text: 'Maaf no order ID anda tidak ada',
                    visible: true
                };
                }
            }
        },
        handleSuccess(){

        },
        handleError(){

        },
        async getKbli(){

        },
        async exportExcel(){

        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    async mounted() { 
        this.$store.dispatch('setOverlay', false)
    },
    watch: {
    }
}
</script>

<style scoped>

</style>